import * as R from 'ramda'
import slugify from '@sindresorhus/slugify'
import { notNilOrEmpty, getYoutubeUrlId, mapIndexed } from '../lib/Helpers'
import {exhibitorRelations} from '../sanity/fragments/exhibitors';

export default class Exhibitor {
  constructor(exhibitor, sanityClient, userId, original) {
    this.userId = userId
    this.sanityClient = sanityClient
    this.exhibitor = { ...exhibitor }
    this._original = exhibitor._id != null ? { ...original } : {}
  }

  isNew() {
    return !this.exhibitor._id
  }

  toCreate() {
    const slug = `${slugify(this.exhibitor.title)}-${
      this.exhibitor.solution_id
    }`

    const validation = {}

    if (notNilOrEmpty(this.exhibitor.bc__company_who_are_you_img)) {
      validation.bc__company_who_are_you_img = this.exhibitor.bc__company_who_are_you_img
    }

    return {
      _id: `drafts.${slug}-${this.exhibitor.challengeType}`,
      _type: 'exhibitors',
      title: this.exhibitor.title,
      solution_id: this.exhibitor.solution_id,
      company_address: this.exhibitor.company_address,
      company_address_city: this.exhibitor.company_address_city,
      company_address_state: this.exhibitor.company_address_state,
      company_address_zipcode: this.exhibitor.company_address_zipcode,
      company_phone: this.exhibitor.company_phone,
      company_primary_contact: this.exhibitor.company_primary_contact,
      company_primary_contact_email: this.exhibitor
        .company_primary_contact_email,
      company_url: this.exhibitor.company_url,
      company_zoom_url: this.exhibitor.company_zoom_url,
      company_info_text: this.exhibitor.company_info_text,
      company_intro_video: getYoutubeUrlId(this.exhibitor.company_intro_video),
      tawkto_chat_link: this.exhibitor.tawkto_chat_link,
      company_videos: getYoutubeUrlId(this.exhibitor.company_video_url),
      sba_status: this.exhibitor.sba_status,
      other_business_status: this.exhibitor.other_business_status,
      minority_owned: this.exhibitor.minority_owned,
      veteran_owned: this.exhibitor.veteran_owned,
      wbe: this.exhibitor.wbe,
      country: this.exhibitor.country,
      challengeType: {
        _ref: this.exhibitor.challengeType,
        _key: this.exhibitor.challengeType,
        _type: 'reference'
      },
      categories: {
        _ref: this.exhibitor.category,
        _key: this.exhibitor.category,
        _type: 'reference'
      },
      submission_details_url: this.exhibitor.submission_details_url,

      slug: {
        type: 'slug',
        current: slug
      },
      bc__who_are_you: this.exhibitor.bc__who_are_you,
      user: {
        _ref: this.userId,
        _key: this.userId,
        _type: 'reference'
      },
      ...validation
    }
  }

  toUpdate() {
    const slug = `${slugify(this.exhibitor.title)}-${
      this.exhibitor.solution_id
    }`

    let updates = {}
    if (this.exhibitor.challengeType !== this._original.challengeType) {
      updates.challengeType = {
        _ref: this.exhibitor.challengeType,
        _key: this.exhibitor.challengeType,
        _type: 'reference'
      }
    }

    if (this.exhibitor.category !== this._original.category) {
      updates.categories = {
        _ref: this.exhibitor.category,
        _key: this.exhibitor.category,
        _type: 'reference'
      }
    }

    if (this.exhibitor.slug.current !== slug) {
      updates.slug = {
        type: 'slug',
        current: slug
      }
    }

    if (notNilOrEmpty(this.exhibitor.bc__company_who_are_you_img)) {
      updates.bc__company_who_are_you_img = this.exhibitor.bc__company_who_are_you_img
    }

    return {
      _id: this.exhibitor._id,
      _type: 'exhibitors',
      title: this.exhibitor.title,
      company_address: this.exhibitor.company_address,
      company_address_city: this.exhibitor.company_address_city,
      company_address_state: this.exhibitor.company_address_state,
      company_address_zipcode: this.exhibitor.company_address_zipcode,
      company_phone: this.exhibitor.company_phone,
      company_primary_contact: this.exhibitor.company_primary_contact,
      company_primary_contact_email: this.exhibitor
        .company_primary_contact_email,
      company_url: this.exhibitor.company_url,
      company_zoom_url: this.exhibitor.company_zoom_url,
      company_info_text: this.exhibitor.company_info_text,
      // company_intro_video: this.exhibitor.company_intro_video,
      company_intro_video: getYoutubeUrlId(this.exhibitor.company_intro_video),
      company_videos: getYoutubeUrlId(this.exhibitor.company_video_url),
      tawkto_chat_link: this.exhibitor.tawkto_chat_link,
      bc__who_are_you: this.exhibitor.bc__who_are_you,
      submission_details_url: this.exhibitor.submission_details_url,
      sba_status: this.exhibitor.sba_status,
      other_business_status: this.exhibitor.other_business_status,
      minority_owned: this.exhibitor.minority_owned,
      veteran_owned: this.exhibitor.veteran_owned,
      wbe: this.exhibitor.wbe,
      country: this.exhibitor.country,
      ...updates
    }
  }

  async createExhibitor() {
    try {
      const doc = this.toCreate()
      const newExhibitor = await this.sanityClient.create(doc)
      await this.sanityClient.assets
        .upload('image', this.exhibitor.company_img, {
          filename: this.exhibitor.company_img_name
        })
        .then(imageAsset => {
          return this.sanityClient
            .patch(newExhibitor._id)
            .set({
              company_img: {
                _type: 'image',
                asset: {
                  _type: 'reference',
                  _ref: imageAsset._id
                }
              }
            })
            .commit()
        })
      if (notNilOrEmpty(this.exhibitor.booth_hero_img)) {
        await this.sanityClient.assets
          .upload('image', this.exhibitor.booth_hero_img, {
            filename: this.exhibitor.booth_hero_img_name
          })
          .then(imageAsset => {
            return this.sanityClient
              .patch(newExhibitor._id)
              .set({
                booth_hero_img: {
                  _type: 'image',
                  asset: {
                    _type: 'reference',
                    _ref: imageAsset._id
                  }
                }
              })
              .commit()
          })
      }
      if (notNilOrEmpty(this.exhibitor.bc__company_who_are_you_img)) {
        await this.sanityClient.assets
          .upload('image', this.exhibitor.bc__company_who_are_you_img, {
            filename: this.exhibitor.bc__company_who_are_you_img_name
          })
          .then(imageAsset => {
            return this.sanityClient
              .patch(newExhibitor._id)
              .set({
                bc__company_who_are_you_img: {
                  _type: 'image',
                  asset: {
                    _type: 'reference',
                    _ref: imageAsset._id
                  }
                }
              })
              .commit()
          })
      }
      if (notNilOrEmpty(this.exhibitor.company_file_asset)) {
        return Promise.all(
          mapIndexed((file, index) => {
            return this.sanityClient.assets
              .upload('file', file)
              .then(fileAsset => {
                return this.sanityClient
                  .patch(newExhibitor._id)
                  .setIfMissing({ company_file_asset: [] })
                  .insert('after', 'company_file_asset[-1]', [
                    {
                      _type: 'file',
                      _key: fileAsset.assetId,
                      asset: {
                        _type: 'reference',
                        _ref: fileAsset._id
                      }
                    }
                  ])
                  .commit()
              })
          })(this.exhibitor.company_file_asset)
        ).then(() => this.getLatestDocument(newExhibitor._id))
      }
      return this.getLatestDocument(newExhibitor._id)
    } catch (error) {
      throw error
    }
  }

  async updateExhibitor() {
    try {
      const { _id, ...updates } = this.toUpdate()
      const updatedExhibitor = await this.sanityClient
        .patch(_id)
        .set(updates)
        .commit()
      if (this._original.company_img !== this.exhibitor.company_img) {
        await this.sanityClient.assets
          .upload('image', this.exhibitor.company_img, {
            filename: this.exhibitor.company_img_name
          })
          .then(imageAsset => {
            return this.sanityClient
              .patch(_id)
              .set({
                company_img: {
                  _type: 'image',
                  asset: {
                    _type: 'reference',
                    _ref: imageAsset._id
                  }
                }
              })
              .commit()
          })
      }
      if (this._original.booth_hero_img !== this.exhibitor.booth_hero_img) {
        await this.sanityClient.assets
          .upload('image', this.exhibitor.booth_hero_img, {
            filename: this.exhibitor.booth_hero_img_name
          })
          .then(imageAsset => {
            return this.sanityClient
              .patch(_id)
              .set({
                booth_hero_img: {
                  _type: 'image',
                  asset: {
                    _type: 'reference',
                    _ref: imageAsset._id
                  }
                }
              })
              .commit()
          })
      }
      if (
        notNilOrEmpty(this.exhibitor.bc__company_who_are_you_img) &&
        this._original.bc__company_who_are_you_img !==
          this.exhibitor.bc__company_who_are_you_img
      ) {
        await this.sanityClient.assets
          .upload('image', this.exhibitor.bc__company_who_are_you_img, {
            filename: this.exhibitor.bc__company_who_are_you_img_name
          })
          .then(imageAsset => {
            return this.sanityClient
              .patch(_id)
              .set({
                bc__company_who_are_you_img: {
                  _type: 'image',
                  asset: {
                    _type: 'reference',
                    _ref: imageAsset._id
                  }
                }
              })
              .commit()
          })
      }
      if (
        this.exhibitor.company_file_asset !==
          this._original.company_file_asset &&
        notNilOrEmpty(this.exhibitor.company_file_asset)
      ) {
        return Promise.all(
          mapIndexed((file, index) => {
            return this.sanityClient.assets
              .upload('file', file)
              .then(fileAsset => {
                return this.sanityClient
                  .patch(_id)
                  .setIfMissing({ company_file_asset: [] })
                  .insert('after', 'company_file_asset[-1]', [
                    {
                      _type: 'file',
                      _key: fileAsset.assetId,
                      asset: {
                        _type: 'reference',
                        _ref: fileAsset._id
                      }
                    }
                  ])
                  .commit()
              })
          })(this.exhibitor.company_file_asset)
        ).then(() => this.getLatestDocument(_id))
      }
      return this.getLatestDocument(_id)
    } catch (error) {
      throw error
    }
  }

  async getLatestDocument(id) {
    const results = await this.sanityClient
      .fetch(
        `*[_id == "${id}"]${exhibitorRelations}`
      )
      .catch(reason => {
        // Track the reason here
        console.log(reason)
        if (typeof window !== 'undefined') {
          window.alert(
            'Failed to fetch an exhibitor record for your account, our service might be down, apologies for the inconvenience.'
          )
        }
      })
    // console.log('RESULTS', results)
    return results[0]
  }
}
